import Head from "next/head";
import { useEffect } from "react";
import { useTranslation } from "app";
import { IHrefLang } from "contentful/types/generated/contentful";
import { dataLayerEvents } from "domain/SeoAndAnalytics/GoogleAnalytics/utils";
import { routesUtil } from "domain/Routing/utils/routesUtil";
import { seoSchemaUtilities } from "../utils/seoSchemaUtilities";
import { seoMeta } from "../utils/getMeta";
import { socialMediaMeta } from "../utils/getSocialMediaMeta";
import { schemaDefaultValues } from "../utils/getSchemaDefaultValues";
import { seoSchema } from "../utils/seoSchema";
import { getFullCanonicalUrl } from "../utils/getFullCanonicalUrl";
import { useRouter } from "next/router";
import { PricingPackage } from "domain/Pricing/PricingPage/Pricing.types";

export const SCHEMA_ORG_URL = "https://schema.org";

export enum RobotsMeta {
  NONE = "none", // Equals noindex, nofollow
  NO_INDEX = "noindex",
  NO_FOLLOW = "nofollow",
  ALL = "all", // Default Value for Indexation
}

export type TSeoMeta = {
  name: string;
  content: string;
};

export interface ISeoSchema {
  organization?: {
    logo?: string;
    description?: string;
    url?: string;
    brand?: {
      aggregateRating?: {
        ratingValue: number;
        reviewCount: number;
      };
    };
  };
  article?: {
    description?: string;
    url?: string;
    headline?: string;
    author?: string;
    datePublished?: string;
    articleSection?: string;
  };
  webPage?: {
    breadcrumb?: {
      itemListElement: Array<{
        position: number;
        name: string;
        item: string;
      }>;
    };
  };
  blogPost?: {
    articleBody: string;
    createdAt: string;
    updatedAt: string;
    image: string;
  };
  faqPage?: {
    mainEntity?: Array<{
      "@type": string;
      name: string;
      acceptedAnswer: {
        "@type": string;
        text: string;
      };
    }>;
  };
  product?: {
    priceCurrency: string;
    image: string;
    externalPricing?: PricingPackage[];
    name?: string;
    description?: string;
    packages: {
      singleReport: { title: string; price: string; description: string };
      advanced: { title: string; price: string; description: string };
      professional: { title: string; price: string; description: string };
    };
  };
  blogPosts?: {
    "@type": string;
    headline: string;
    image: string;
    datePublish: string;
    author: {
      "@type": string;
      name: string;
      url: string;
    }[];
    url: string;
  }[];
}

export interface ISeoSocialMedia {
  title: string;
  description: string;
  image: string;
}

export interface IDataLayer {
  pageName: string;
  pageTitle: string;
  pageType: string;
  pageCountry: string;
  pageLocale: string;
  vin?: string | string[];
  vinHashed?: boolean;
  vinEntries?: number;
}

export interface ISeoSchemaDefaultValues {
  organization: {
    name: string;
    legalName: string;
    url: string;
    sameAs?: Array<string>;
    brand: {
      logo: string;
    };
    address: {
      streetAddress: string;
      addressLocality: string;
      postalCode: string;
      addressCountry: string;
    };
  };
  webSite: {
    name: string;
    url: string;
  };
  webPage: {
    name: string;
    description: string;
    inLanguage: string;
  };
  blogPost: {
    headline: string;
    url: string;
    author: string;
    publisher: {
      name: string;
      logo: {
        name: string;
        width: number;
        height: number;
        url: string;
      };
    };
  };
}

interface Props {
  title?: string;
  description?: string;
  image?: string;
  keywords?: string[];
  schema?: ISeoSchema;
  socialMedia?: ISeoSocialMedia;
  canonical?: string;
  dataLayer: IDataLayer;
  robotsMeta?: string;
  hrefLang?: IHrefLang[];
}

let prerenderDone = false;
const companyNames = seoSchemaUtilities.getCompanyNames();

const schemaDefaults = {
  organization: {
    name: companyNames.name,
    legalName: companyNames.legalName,
    address: seoSchemaUtilities.getCompanyAddress(),
    sameAs: seoSchemaUtilities.getSocialMediaUrls(),
  },
  webSite: {
    name: companyNames.name,
  },
  blogPost: {
    author: companyNames.name,
    publisher: {
      name: companyNames.name,
    },
  },
};

const renderSocialMediaMeta = (socialMedia) =>
  socialMedia.map((metaTag) =>
    metaTag.name.startsWith("og:") ? (
      <meta key={`meta-${metaTag.name}`} property={metaTag.name} content={metaTag.content} />
    ) : (
      <meta key={`meta-${metaTag.name}`} name={metaTag.name} content={metaTag.content} />
    )
  );

const renderMetaWithDefaults = (metaWithDefaults) =>
  metaWithDefaults.map((metaTag) => (
    <meta key={`meta-${metaTag.name}`} name={metaTag.name} content={metaTag.content} />
  ));

const renderHrefLang = (hrefLang) =>
  hrefLang.map((hrefTag) => (
    <link
      key={`href-${hrefTag.sys?.id}`}
      rel="alternate"
      href={hrefTag.fields?.href}
      hrefLang={hrefTag.fields?.locale}
    />
  ));

const renderRobots = (robotsMeta: string) => robotsMeta && <meta name="robots" content={robotsMeta} />;

const Seo: React.FC<Props> = ({
  title,
  description,
  schema,
  keywords,
  canonical,
  image,
  dataLayer,
  robotsMeta,
  hrefLang,
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const router = useRouter();
  const isHomepage = routesUtil.isHomepage(router.asPath);
  const isCareers = routesUtil.isCareers(router.asPath);

  const titleTemplate = `${title} | CARFAX`;
  const socialMediaImage = image || schema?.blogPost?.image;
  const metaWithDefaults = seoMeta.getMeta(description);
  const socialMedia = socialMediaMeta.getSocialMediaMeta(description, title, socialMediaImage);
  const schemaDefault = schemaDefaultValues.getSchemaDefaultValues(
    schemaDefaults,
    title,
    titleTemplate,
    description,
    language
  );

  const schemaOrganization = seoSchema.getSchemaOrganization(schema, schemaDefault);
  const schemaFaqPage = seoSchema.getSchemaFaqPage(schema);
  const schemaArticle = seoSchema.getSchemaArticle(schema);
  const schemaWebSite = seoSchema.getSchemaWebSite(schemaDefault);
  const schemaWebPage = seoSchema.getSchemaWebPage(schema, schemaDefault);
  const schemaBlogPost = seoSchema.getSchemaBlogPost(schema, schemaDefault);
  const schemaBlogMainPage = seoSchema.getSchemaBlogMainPage(schema, schemaDefault);
  const { schemaProduct } = seoSchema.getSchemaProduct(schema, schemaDefault);
  const schemaArticleAndProduct = seoSchema.getSchemaArticleAndProduct(schema, schemaDefault);
  const keywordsString = keywords && keywords.join(",");

  useEffect(() => {
    if (prerenderDone) {
      dataLayerEvents.submitSyntheticPageViewEvent(
        dataLayer.pageName,
        dataLayer.pageTitle,
        dataLayer.pageType,
        dataLayer.vin,
        dataLayer.vinHashed,
        dataLayer.pageLocale,
        dataLayer.pageCountry,
        dataLayer.vinEntries
      );
    }
    return () => {
      prerenderDone = true;
    };
  }, []);

  return (
    <Head>
      <title>{titleTemplate}</title>
      {hrefLang ? renderHrefLang(hrefLang) : null}
      {canonical ? <link rel="canonical" href={getFullCanonicalUrl(canonical)} /> : null}
      {renderMetaWithDefaults(metaWithDefaults)}
      {renderRobots(robotsMeta)}
      {keywords && <meta name="keywords" content={keywordsString} />}
      {socialMedia && renderSocialMediaMeta(socialMedia)}
      {isHomepage && schemaOrganization}
      {!schemaBlogPost && schemaArticle}
      {schemaFaqPage}
      {isHomepage && schemaWebSite}
      {!isHomepage && !schemaBlogMainPage && !schemaBlogPost && schemaWebPage}
      {schemaBlogPost}
      {schemaBlogMainPage}
      {!isHomepage && schemaProduct}
      {!isCareers && !isHomepage && schemaArticleAndProduct}
    </Head>
  );
};

export default Seo;
