export enum CurrencyMapShortForm {
  EURO = "EUR",
  ZLOTY = "PLN",
}

const formatCurrencyToShortForm = (currency: string): string => {
  const currencyMap = {
    EURO: CurrencyMapShortForm.EURO,
    ZLOTY: CurrencyMapShortForm.ZLOTY,
  };
  return currencyMap[currency] || currency;
};

export { formatCurrencyToShortForm };
